<template>
  <div class="">
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'expenses-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer
        </button>
      </div>
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence Nº</th>
          <th scope="col">Désignation</th>
          <th scope="col">Employé</th>
          <th scope="col">Date d'expense</th>
          <th scope="col">Montant</th>
          <th scope="col">Mode de paiement</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(expense, index) in expenses" :key="index++">
          <td>
            {{ index }}
          </td>
          <td class="p-0 m-0">
            {{ expense.reference }}
          </td>
          <td>
            {{ expense.title }}
          </td>
          <td>
            <span v-if="expense.employee">
              {{ expense.employee.fullName }}
            </span>
          </td>
          <td>{{ expense.date }}</td>
          <td >{{ expense.amount }}</td>
          <td >
            {{ expense.paymentMethod }}
          </td>
          <td >
            <button class="btn">
              <i class="bi bi-coin fs-5"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("expense", {
      expenses: "getExpenses",
    }),
  },
  mounted() {
    this.$store.dispatch("expense/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("expense/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("expense/getAll");
    },

    async listExpenses(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("expense/getTodays");
      } else {
        return await this.$store.dispatch("expense/getAll");
      }
    },
    async sort(value) {
      return await this.$store.commit("expense/sort", value);
    },
  },
};
</script>
